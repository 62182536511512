import React from 'react';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Helvetica',
    fontWeight: 600,
  },
  card: {
    display: 'block',
    maxWidth: 425,
  },
  media: {
    height: 150,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#383836',
    },
    secondary: {
      main: '#bd2026',
    },
  },
});

const VehicleCard = props => {
  const classes = useStyles();
  const { vehicleImage, vehicleName, vehiclePrice } = props;

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component="img"
            width="400"
            height="200"
            image={vehicleImage}
            title={vehicleName}
            draggable="false"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h6">
              {vehicleName}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          classes={{
            root: classes.root,
          }}
        >
          <Button className={classes.root} size="small" color="secondary">
            View Listing
          </Button>
          <Typography gutterBottom variant="h6" component="h6">
            {vehiclePrice}
          </Typography>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

export default VehicleCard;
