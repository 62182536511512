import React from 'react';
import styles from './index.module.css';

const Video = ({ videoSrcURL }) => (
  <div className={styles.videoContainer}>
    <video className={styles.video} autoPlay muted playsInline loop>
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </div>
);

export default Video;
