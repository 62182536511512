import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';

import styles from './index.module.css';


// 249485636042254
const Facebook = () => {
  return (
    <div className={styles.facebook}>
      <FacebookProvider appId="2838902079461554">
        <Page
          href="https://www.facebook.com/rrsautogroup/"
          tabs="timeline"
          width="500px"
          height="675px"
        />
      </FacebookProvider>
    </div>
  );
};

export default Facebook;
