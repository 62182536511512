import React from 'react';

import Img from 'gatsby-image';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Fade from 'react-reveal/Fade';

import Layout from '../components/layout';
import Card from '../components/card';
import SEO from '../components/seo';
import Video from '../components/video';

import Facebook from '../components/facebook';
import MailChimp from '../components/mailchimp';
import VehicleCard from '../components/vehicle';

import styles from './index.module.css';

import useQuery from '../queries/index-query';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsiveLandingCarousel = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const IndexPage = () => {
  const {
    banner,
    videoBanner,
    aboutUsImage,
    aboutUsCard,
    consignmentImage,
    consignmentCard,
    vehicleCard,
    vehicle,
    socialMediaCard,
    metaData,
  } = useQuery();

  const contentType = contentQuery => {
    const type = contentQuery.media.file.contentType.split('/');
    return type[0];
  };

  const inventorySize = () => {
    return vehicle.edges.filter(edge => edge.node.showListing).length;
  };

  return (
    <Layout>
      <SEO
        title="Home"
        image={metaData.metaImage.file.url}
        video={videoBanner.media.file.url}
      />
      <div style={{ height: 'auto' }}>
        <Carousel
          infinite
          autoPlay
          autoPlaySpeed={3000}
          transitionDuration={500}
          responsive={responsiveLandingCarousel}
          draggable
        >
          <Img
            className={styles.landingImage}
            fluid={banner.media.fluid}
            alt="landing-banner"
          />
          <Video
            className={styles.landingImage}
            videoSrcURL={videoBanner.media.file.url}
            alt="video-banner"
          />
        </Carousel>
      </div>

      <div className={styles.aboutContentContainer}>
        <Fade duration={3000}>
          <div className={styles.aboutContentItem}>
            <Img
              className={styles.aboutContentImage}
              fluid={aboutUsImage.media.fluid}
              alt="about-us"
            />
          </div>
        </Fade>

        <div className={styles.aboutContentCard}>
          <Fade up duration={3000}>
            <Card
              className={styles.aboutCard}
              title={aboutUsCard.title}
              subtitle={aboutUsCard.subtitle}
              desc={aboutUsCard.description.description}
              buttonText={aboutUsCard.buttonText}
              route="/contact"
            />
          </Fade>
        </div>
      </div>

      <div className={styles.consignmentContentContainer}>
        <div className={styles.imgBackground}>
          <Img
            className={styles.consignmentImage}
            fluid={consignmentImage.media.fluid}
            alt="murcielago-consignment"
          />
        </div>
        <Fade right duration={2500}>
          <div className={styles.consignmentContentCard}>
            <Card
              className={styles.consignmentCard}
              backgroundColor="white"
              title={consignmentCard.title}
              subtitle={consignmentCard.subtitle}
              desc={consignmentCard.description.description}
              buttonText={consignmentCard.buttonText}
              route="/consignment"
            />
          </div>
        </Fade>
      </div>
      <div className={styles.vehicleContentContainer}>
        <Fade left duration={1500}>
          <Card
            className={styles.vehicleCard}
            title={vehicleCard.title}
            subtitle={vehicleCard.subtitle}
          />
        </Fade>
        <Carousel
          infinite
          autoPlay
          autoPlaySpeed={3000}
          transitionDuration={500}
          responsive={responsive}
          draggable
          itemClass={styles.itemClass}
          containerClass={
            inventorySize < 3 ? styles.lessThanThree : styles.containerClass
          }
        >
          {vehicle.edges
            .filter(edge => edge.node.showListing)
            .map(edge => {
              return (
                <a
                  className={styles.vehicleItem}
                  href={edge.node.vehicleUrl}
                  key={edge.node.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VehicleCard
                    vehicleName={edge.node.name}
                    vehicleImage={edge.node.media.fixed.src}
                    vehicleUrl={edge.node.vehicleUrl}
                    vehiclePrice={edge.node.vehiclePrice}
                  />
                </a>
              );
            })}
        </Carousel>
      </div>
      <div className={styles.socialMediaContentContainer}>
        <Fade up duration={3000}>
          <div className={styles.socialMediaContent}>
            <Card
              className={styles.socialMediaCard}
              title={socialMediaCard.title}
              desc={socialMediaCard.description.description}
            />
            <div className={styles.mailchimp}>
              <MailChimp />
            </div>
          </div>
        </Fade>

        <Facebook />
      </div>
    </Layout>
  );
};

export default IndexPage;
