import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '../button';

const useStyles = {
  root: {
    '& label.Mui-focused': {
      color: '#bd2026',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#bd2026',
      },
    },
  },
  emailField: {
    width: '300px',
  },
  button: {
    margin: '15px 0px',
    fontFamily: 'Arial Black, serif',
    letterSpacing: '2px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    color: 'white',
    backgroundColor: '#191f23',
    textAlign: 'center',
    cursor: 'pointer',
    width: '300px',
    '&:hover': {
      opacity: '0.7',
    },
  },
};

class MailChimp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      formError: false,
      open: false,
      message: '',
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState(prevState => ({ open: !prevState.open }));
  };

  handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  handleSubmit = async e => {
    const { email } = this.state;
    e.preventDefault();
    const result = await addToMailchimp(email, {
      'group[8893][4]': '4',
    });
    this.setState({
      message: result.msg,
      open: true,
    });

    const status = JSON.stringify(result.result);
    if (status === '"error"') {
      this.setState({ formError: true });
    }

    const error = 'Error. You are already subscribed to our emailing list.';
    if (email !== null && status === '"error"') {
      this.setState({ message: error });
    }
  };

  render() {
    const { classes } = this.props;
    const { open, formError, message } = this.state;

    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.handleSubmit}
      >
        <TextField
          className={classes.emailField}
          name="email"
          type="email"
          onChange={this.handleChange}
          id="outlined-basic"
          label="Email"
          variant="outlined"
          InputLabelProps={{ required: false }}
          required
        />
        <input
          type="checkbox"
          style={{ display: 'none' }}
          value="4"
          name="group[8893][4]"
          checked
          readOnly
        />
        <Button className={classes.button} type="submit" text="SUBSCRIBE" />
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          {formError ? (
            <Alert onClose={this.handleClose} severity="error">
              {message}
            </Alert>
          ) : (
            <Alert onClose={this.handleClose} severity="success">
              {message}
            </Alert>
          )}
        </Snackbar>
      </form>
    );
  }
}

export default withStyles(useStyles)(MailChimp);
