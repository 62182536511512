import { graphql, useStaticQuery } from 'gatsby';

const useQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        banner: contentfulContentMedia(
          contentful_id: { eq: "2S7HmLS2pjizOyLYGn7PUh" }
        ) {
          media {
            fluid(maxWidth: 1920, maxHeight: 900, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              contentType
              url
            }
          }
        }
        videoBanner: contentfulContentMedia(
          contentful_id: { eq: "3XWXo0EGqKYykNyrsqQTeN" }
        ) {
          media {
            fluid(maxWidth: 1920, maxHeight: 900, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              contentType
              url
            }
          }
        }
        aboutUsImage: contentfulContentMedia(
          contentful_id: { eq: "5GRgnuhqFkCtouS1jW0kLj" }
        ) {
          media {
            fluid(maxWidth: 1920, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        aboutUsCard: contentfulCard(
          contentful_id: { eq: "3z7ooDB00h5VhyrszwH5pW" }
        ) {
          title
          subtitle
          description {
            description
          }
          buttonText
        }
        consignmentImage: contentfulContentMedia(
          contentful_id: { eq: "6CvWaoytTwVukJse5f05cI" }
        ) {
          media {
            fluid(maxWidth: 1920, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        consignmentCard: contentfulCard(
          contentful_id: { eq: "4vzOtCe863VPvhkQTdpVpV" }
        ) {
          title
          subtitle
          description {
            description
          }
          buttonText
        }
        vehicleCard: contentfulCard(
          contentful_id: { eq: "6PsApSlASTwJfP8LH6wK1o" }
        ) {
          title
          subtitle
          description {
            description
          }
          buttonText
        }
        vehicle: allContentfulVehicleListings {
          edges {
            node {
              id
              name
              media {
                id
                fixed(quality: 80, width: 400) {
                  ...GatsbyContentfulFixed
                }
              }
              vehicleUrl
              vehiclePrice
              showListing
            }
          }
        }
        socialMediaCard: contentfulCard(
          contentful_id: { eq: "2lU4tbm0id00Homx97lXEL" }
        ) {
          title
          subtitle
          description {
            description
          }
          buttonText
        }
        metaData: contentfulMetaData {
          metaImage {
            file {
              url
            }
          }
        }
      }
    `,
  );
  return query;
};

export default useQuery;
